import React from "react";

type Props = {
  color?: string;
  opacity?: string;
};

export const BagOutlineIcon = ({ color = "black", opacity = "1" }: Props) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0025 6C15.0025 7.06087 14.5811 8.07828 13.8309 8.82843C13.0808 9.57857 12.0634 10 11.0025 10C9.94165 10 8.92424 9.57857 8.17409 8.82843C7.42395 8.07828 7.00252 7.06087 7.00252 6M2.63574 5.40138L1.93574 13.8014C1.78536 15.6059 1.71017 16.5082 2.0152 17.2042C2.2832 17.8157 2.74755 18.3204 3.3347 18.6382C4.00299 19 4.90838 19 6.71916 19H15.2859C17.0967 19 18.002 19 18.6703 18.6382C19.2575 18.3204 19.7218 17.8157 19.9898 17.2042C20.2949 16.5082 20.2197 15.6059 20.0693 13.8014L19.3693 5.40138C19.2399 3.84875 19.1752 3.07243 18.8314 2.48486C18.5286 1.96744 18.0778 1.5526 17.537 1.29385C16.9229 1 16.1439 1 14.5859 1L7.41916 1C5.86114 1 5.08213 1 4.46803 1.29384C3.92726 1.5526 3.47642 1.96744 3.17364 2.48486C2.82982 3.07243 2.76512 3.84875 2.63574 5.40138Z"
        stroke={color}
        stroke-opacity={opacity}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
