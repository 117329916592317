import * as React from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import { useAccount } from "./hooks/account";
import SignupPage from "./pages/signup";
import OnboardPage from "./pages/onboard";
import MyLink from "./pages/my_link";
import InfluencerLanding from "./pages/influencer";
import MyStore from "./pages/influencer/my_store";
import FindProduct from "./pages/influencer/find_product";
import InfluencerPublicListing from "./pages/influencer/public_listing";
import { Spin } from "antd";
import { createStyle } from "./utils/style";
import { CONFIG } from "./config";
import LinkDetail from "./pages/influencer/link_detail";
import InfluProfile from "./pages/influencer/profile";
import EditProfile from "./pages/influencer/edit_profile";
import UsageIntent from "./pages/influencer/usage_intent";
import AboutYou from "./pages/influencer/about";
import MyProduct from "./pages/influencer/self_product_link";

const Loader = () => {
  return (
    <div style={styles.container}>
      <Spin size="large" />
    </div>
  );
};

const ExternalRedirect = ({ url }: { url: string }) => {
  const redirectToExternalUrl = () => {
    window.location.href = url;
  };

  redirectToExternalUrl();
  return null;
};

export default function App() {
  let location = useLocation();
  const account = useAccount();

  React.useEffect(() => {
    account.init();
  }, []);

  if (!account.initilized) return <Loader />;
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* <Route
          index
          element={<ExternalRedirect url="https://www.meralink.site" />}
        /> */}
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/onboard"
          element={
            <RequireAuth>
              <OnboardPage />
            </RequireAuth>
          }
        />
        <Route path="/my_link" element={<MyLink />} />
        <Route
          path="/intent"
          element={
            <RequireAuth>
              <UsageIntent />
            </RequireAuth>
          }
        />
        <Route
          path="/about"
          element={
            <RequireAuth>
              <AboutYou />
            </RequireAuth>
          }
        />

        <Route
          path="/home/*"
          element={
            <RequireAuth>
              <InfluencerLanding />
            </RequireAuth>
          }
        >
          <Route index element={<MyStore />} />
          <Route path="find_product" element={<FindProduct />} />
          <Route path="my_product" element={<MyProduct />} />
          <Route path="link" element={<LinkDetail />} />
          <Route path="profile/*">
            <Route index element={<InfluProfile />} />
            <Route path="edit" element={<EditProfile />} />
            <Route
              path="*"
              element={
                <Navigate
                  to="/home/profile"
                  state={{ from: location }}
                  replace
                />
              }
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/home" state={{ from: location }} replace />}
          />
        </Route>

        <Route path="/:id" element={<InfluencerPublicListing />} />

        <Route
          path="*"
          element={<Navigate to="/home" state={{ from: location }} replace />}
        />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Outlet />
      {!CONFIG.isProduction && (
        <div
          style={{
            background: "red",
            position: "fixed",
            top: 0,
            right: 0,
            zIndex: 100,
          }}
        >
          {CONFIG.ENV}
        </div>
      )}
    </div>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let account = useAccount();
  let location = useLocation();

  if (!account.firebaseUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signup" state={{ from: location }} replace />;
  }

  return children;
}

const styles = createStyle({
  container: {
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
});
